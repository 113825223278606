export class LanguageText {
  readonly locale: string;
  readonly text: string;
  readonly value?: any | null;

  constructor(
    locale: string,
    text: string,
    value?: any | null
  ) {
    this.locale = locale;
    this.text = text;
    this.value = value;
  }
}

export enum ValueType {
  BOOLEAN = "boolean",
  IMAGE = "image",
  INT = "int",
  STRING = "string",
}

export enum ValueFieldKey {
  DEPTH = "depth",
  HAS_WATER = "hasWater",
  IMAGE = "images",
  IS_POLLUTED = "isPolluted",
  IS_SHADED = "isShaded",
  IS_TEMPORARY = "temporary",
  IS_VEGETATION = "isVegetation",
  SIZE = "size",
  TYPE = "type",
}

export namespace ValueFieldKey {
  export function isKnownValueFieldKey(value: string | undefined): boolean {
    return value === ValueFieldKey.DEPTH
      || value === ValueFieldKey.HAS_WATER
      || value === ValueFieldKey.IMAGE
      || value === ValueFieldKey.IS_POLLUTED
      || value === ValueFieldKey.IS_SHADED
      || value === ValueFieldKey.IS_TEMPORARY
      || value === ValueFieldKey.IS_VEGETATION
      || value === ValueFieldKey.SIZE
      || value === ValueFieldKey.TYPE;
  }
}

export class NewWaterSourceWizardStep {
  readonly valueFieldKey: ValueFieldKey | string;
  readonly isArray: boolean;
  readonly valueType: ValueType;
  readonly question?: LanguageText[];
  readonly options?: LanguageText[];

  constructor(
    valueFieldKey: ValueFieldKey | string,
    isArray: boolean,
    valueType: ValueType,
    question?: LanguageText[],
    options?: LanguageText[]
  ) {
    this.valueFieldKey = valueFieldKey;
    this.isArray = isArray;
    this.valueType = valueType;
    this.question = question;
    this.options = options;
  }

  hasAllLanguages(requiredLanguageCodes: string[]): boolean {
    const questionLanguageCodes = new Set(this.question?.map((languageText: LanguageText) => languageText.locale));
    const optionLanguageCodes = new Set(this.options?.map((languageText: LanguageText) => languageText.locale));
    return requiredLanguageCodes.every((code: string) => questionLanguageCodes.has(code)
      && (this.options?.length === 0 || optionLanguageCodes.has(code)));
  }
}

export class CampaignConfig {
  readonly sampleExpiration: number;
  readonly sprayExpiration: number;
  readonly spraySemiExpiration: number;
  readonly surveyExpiration: number;
  readonly surveyBeltRadius: number;
  readonly surveyCoreRadius: number;
  readonly small: number;
  readonly medium: number;
  readonly large: number;
  readonly huge: number;
  readonly zoomLimit: number;
  readonly workModes: string[];
  readonly supportedWaterSourceTypes?: string[] | null;
  readonly newWaterSourceWizard?: NewWaterSourceWizardStep[];
  readonly shouldShowLarvaePresentDialogInTreat?: boolean | null;
  readonly shouldShowAnophelesL12PresentDialogInTreat?: boolean | null;
  readonly shouldShowAnophelesL34PresentDialogInTreat?: boolean | null;
  readonly shouldShowCulexL12PresentDialogInTreat?: boolean | null;
  readonly shouldShowCulexL34PresentDialogInTreat?: boolean | null;
  readonly shouldShowPupaePresentDialogInTreat?: boolean | null;

  constructor(
    sampleExpiration: number,
    sprayExpiration: number,
    spraySemiExpiration: number,
    surveyExpiration: number,
    surveyBeltRadius: number,
    surveyCoreRadius: number,
    small: number,
    medium: number,
    large: number,
    huge: number,
    zoomLimit: number,
    workModes: string[],
    supportedWaterSourceTypes?: string[] | null,
    newWaterSourceWizard?: NewWaterSourceWizardStep[],
    shouldShowLarvaePresentDialogInTreat?: boolean | null,
    shouldShowAnophelesL12PresentDialogInTreat?: boolean | null,
    shouldShowAnophelesL34PresentDialogInTreat?: boolean | null,
    shouldShowCulexL12PresentDialogInTreat?: boolean | null,
    shouldShowCulexL34PresentDialogInTreat?: boolean | null,
    shouldShowPupaePresentDialogInTreat?: boolean | null
  ) {
    this.sampleExpiration = sampleExpiration;
    this.sprayExpiration = sprayExpiration;
    this.spraySemiExpiration = spraySemiExpiration;
    this.surveyExpiration = surveyExpiration;
    this.surveyBeltRadius = surveyBeltRadius;
    this.surveyCoreRadius = surveyCoreRadius;
    this.small = small;
    this.medium = medium;
    this.large = large;
    this.huge = huge;
    this.zoomLimit = zoomLimit;
    this.workModes = workModes;
    this.supportedWaterSourceTypes = supportedWaterSourceTypes;
    this.newWaterSourceWizard = newWaterSourceWizard;
    this.shouldShowLarvaePresentDialogInTreat = shouldShowLarvaePresentDialogInTreat;
    this.shouldShowAnophelesL12PresentDialogInTreat = shouldShowAnophelesL12PresentDialogInTreat;
    this.shouldShowAnophelesL34PresentDialogInTreat = shouldShowAnophelesL34PresentDialogInTreat;
    this.shouldShowCulexL12PresentDialogInTreat = shouldShowCulexL12PresentDialogInTreat;
    this.shouldShowCulexL34PresentDialogInTreat = shouldShowCulexL34PresentDialogInTreat;
    this.shouldShowPupaePresentDialogInTreat = shouldShowPupaePresentDialogInTreat;
  }
}
