import {Injectable} from "@angular/core";
import {Adapter} from "./Adapter";

export class Location {
  center: number[];

  constructor(data: any) {
    this.center = data.features[0].geometry.coordinates;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocationAdapter implements Adapter<Location> {
  adapt(item: any): Location {
    return new Location(item);
  }
}
