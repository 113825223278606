import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {BehaviorSubject, Observable, retry, Subscription, tap} from "rxjs";
import {Task, TaskAdapter, TaskStatus} from "../core/TaskAdapter";
import {environment} from "../../environments/environment";
import {map} from "rxjs/operators";
import Swal from "sweetalert2";
import {Campaign} from "../core/CampaignAdapter";

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private _tasks: BehaviorSubject<Task[] | undefined> = new BehaviorSubject<Task[] | undefined>(undefined);
  private tasksSub: Subscription;

  constructor(private http: HttpClient,
              private adapter: TaskAdapter) {
  }

  get tasks$(): Observable<Task[] | undefined> {
    return this._tasks.asObservable();
  }

  createTask(campaign: Campaign, data: any): Observable<Task> {
    const createTaskUrl = `${environment.BASE_URL}/tasks`;
    return this.http.post(createTaskUrl, data, {
      headers: {
        'Campaign-Id': `${campaign.id}`
      }
    }).pipe(
      map((item: any) => this.adapter.adapt(item, { campaign })),
      tap((task: Task) => {
        let tasks = this._tasks.getValue();

        if (!tasks) {
          tasks = [];
        }

        tasks.push(task);

        this._tasks.next(tasks);
      })
    )
  }

  getTasks(campaign: Campaign, useCache: boolean = true) {
    this.tasksSub?.unsubscribe();
    this._tasks.next(undefined);
    const getTasksUrl = `${environment.BASE_URL}/tasks`;
    let headers: any = {
      'Campaign-Id': `${campaign.id}`
    };

    if (!useCache) {
      headers['Cache-Control'] = 'must-revalidate';
    }

    this.tasksSub = this.http.get(getTasksUrl, {
      headers: headers
    }).pipe(
      retry({
        count: 3,
        delay: 10000,
      }),
      map((data: any) => data.map((item: any) => this.adapter.adapt(item, { campaign }))),
      tap((tasks: Task[]) => {
        this._tasks.next(tasks);
      }),
    ).subscribe({
      error: (err) => {
        Swal.fire({
          text: err.message,
          icon: "error"
        });
      }
    })
  }

  clear() {
    this._tasks.next(undefined);
  }

  assignUser(taskId: number, userId: number, campaign: Campaign): Observable<Task> {
    const assignUserUrl = `${environment.BASE_URL}/tasks/assign`;

    return this.http.post(assignUserUrl, {
      taskId,
      userId,
    }).pipe(
      map((item: any) => this.adapter.adapt(item,{ campaign })),
      tap((task: Task) => {
        const tasks = this._tasks.getValue()!;
        const index = tasks.findIndex((task) => task.id === taskId);

        if (index !== -1) {
          tasks[index] = task;
        } else {
          tasks.push(task);
        }

        this._tasks.next(tasks);
      })
    )
  }

  updateTaskStatus(taskId: number, status: TaskStatus, campaign: Campaign): Observable<Task> {
    const updateAssignmentStatusUrl = `${environment.BASE_URL}/tasks`;

    return this.http.patch(updateAssignmentStatusUrl, {
      taskId: taskId,
      status
    }).pipe(
      map((data: any) => this.adapter.adapt(data, { campaign })),
      tap((changedTask: Task) => {
        const tasks = this._tasks.getValue()!;
        const index = tasks.findIndex((task) => task.id === changedTask.id);
        tasks[index] = changedTask;
        this._tasks.next(tasks);
      })
    )
  }
}
