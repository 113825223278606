<div [ngClass]="{'open--tasks': sidebarService.isOpen && sidebarService.openedContent === 'tasks',
                'open--reports': sidebarService.isOpen && sidebarService.openedContent === 'reports'}"
     class="sidebar-links">
  <div aria-orientation="vertical" class="nav flex-column">
    <a (click)="onClick('tasks')"
       [ngClass]="{'active': sidebarService.isOpen && sidebarService.openedContent === 'tasks' }"
       class="nav-link"
       href="javascript:void(0)">
      <i class="fa-solid fa-list-check"></i>
      <span>{{ 'sidebar.tasks' | translate }}</span>
    </a>
    <a (click)="onClick('reports')"
       [ngClass]="{'active': sidebarService.isOpen && sidebarService.openedContent === 'reports' }"
       class="nav-link"
       href="javascript:void(0)">
      <i class="fa-solid fa-chart-line"></i>
      <span>{{ 'sidebar.reports' | translate }}</span>
    </a>
  </div>
</div>
