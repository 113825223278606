import {Injectable} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable} from "rxjs";
import {Location, LocationAdapter} from "../core/LocationAdapter";
import {v4 as uuidv4} from 'uuid';
import {Search, SearchAdapter} from "../core/SearchAdapter";

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient,
              private searchAdapter: SearchAdapter,
              private locationAdapter: LocationAdapter) {
  }

  getLocation(mapboxId: string, accessToken: string): Observable<Location> {
    return this.http.get(`https://api.mapbox.com/search/searchbox/v1/retrieve/${mapboxId}?session_token=${uuidv4()}&access_token=${accessToken}`)
      .pipe(map((data: any) => this.locationAdapter.adapt(data)));
  }

  getLocations(searchText: string, accessToken: string): Observable<Search[]> {
    return this.http.get(`https://api.mapbox.com/search/searchbox/v1/suggest?q=${searchText}&session_token=${uuidv4()}&access_token=${accessToken}`)
      .pipe(map((data: any) => data.suggestions.map((item: any) => this.searchAdapter.adapt(item))));
  }
}
