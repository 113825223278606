import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";
import {WorkType} from "./TaskAdapter";
import {differenceInDays} from "date-fns/differenceInDays";

export enum Role {
  ADMIN = 1,
  MANAGER = 2,
  WORKER = 4
}

function getRoleEnum(roleValue: number): Role {
  for (let role in Role) {
    if (Role[role as keyof typeof Role] === roleValue) {
      return Role[role as keyof typeof Role] as Role;
    }
  }

  throw new Error(`Role not found: ${roleValue}`);
}

export class User {
  id: number;
  phoneNumber: string;
  fullName: string;
  role: Role;
  hasData: boolean;
  isActivated: boolean;
  irsCertification: boolean;
  larvicidingCertification: boolean;
  enthomologyCertification: boolean;
  campaignIds: number[];
  email?: string;
  teamLeader?: {
    name: string,
    phoneNumber: string
  };
  activeTask?: {
    workType: WorkType,
    villageName: string,
  };
  upcomingTask?: {
    workType: WorkType,
    villageName: string,
  };
  pendingTasksCount?: number;
  syncedOn?: number;
  isOldSync: boolean;

  constructor(
    id: number,
    phoneNumber: string,
    fullName: string,
    role: Role,
    hasData: boolean,
    isActivated: boolean,
    irsCertification: boolean,
    larvicidingCertification: boolean,
    enthomologyCertification: boolean,
    campaignIds: number[],
    email?: string,
    teamLeader?: {
      name: string,
      phoneNumber: string
    },
    activeTask?: {
      workType: WorkType,
      villageName: string,
    },
    upcomingTask?: {
      workType: WorkType,
      villageName: string,
    },
    pendingTasksCount?: number,
    syncedOn?: number,
  ) {
    this.id = id;
    this.phoneNumber = phoneNumber;
    this.fullName = fullName;
    this.role = role;
    this.hasData = hasData;
    this.isActivated = isActivated;
    this.irsCertification = irsCertification;
    this.larvicidingCertification = larvicidingCertification;
    this.enthomologyCertification = enthomologyCertification;
    this.campaignIds = campaignIds;
    this.email = email;
    this.teamLeader = teamLeader;
    this.activeTask = activeTask;
    this.upcomingTask = upcomingTask;
    this.pendingTasksCount = pendingTasksCount;
    this.syncedOn = syncedOn;
    this.isOldSync = syncedOn !== undefined ? differenceInDays(new Date(), new Date(syncedOn)) > 0 : false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {
  adapt(item: any): User {
    return new User(
      item.id,
      item.phoneNumber,
      item.fullName.trim(),
      getRoleEnum(item.role),
      item.hasData,
      item.isActivated,
      item.irsCertification,
      item.larvicidingCertification,
      item.enthomologyCertification,
      item.campaignIds,
      item.email,
      item.teamLeader,
      undefined,
      undefined,
      undefined,
      item.syncedOn
    );
  }
}
