import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";

export enum Status {
  VISITED = 0,
  NOT_AT_HOME = 1,
  REFUSED_1 = 2,
  REFUSED_2 = 3,
  REFUSED_3 = 4,
  REFUSED_4 = 5,
  REFUSED_5 = 6,
  FORBIDDEN = 7,
  VISITED_TASK = 8,
  NOT_AT_HOME_TASK = 9,
  REFUSED_1_TASK = 10,
  REFUSED_2_TASK = 11,
  REFUSED_3_TASK = 12,
  REFUSED_4_TASK = 13,
  REFUSED_5_TASK = 14,
}

export class Action {
  id: number;
  deviceId: string;
  assignmentId: number;
  createdByUser: {
    id: number;
    name: string;
  };
  status: Status;
  reportedOn: number;

  constructor(
    id: number,
    deviceId: string,
    assignmentId: number,
    createdByUser: {
      id: number;
      name: string;
    },
    status: Status,
    reportedOn: number) {
    this.id = id;
    this.deviceId = deviceId;
    this.assignmentId = assignmentId;
    this.createdByUser = createdByUser;
    this.status = status;
    this.reportedOn = reportedOn;
  }
}

export class House {
  id: number;
  deviceId: string;
  villageId: number;
  assignmentId: number;
  history: Action[];
  status: Status;
  location: {
    latitude: number;
    longitude: number;
  };
  createdById: number;
  reportedOn: number;
  modifiedOn: number;

  constructor(
    id: number,
    deviceId: string,
    villageId: number,
    assignmentId: number,
    history: Action[],
    status: Status,
    location: {
      latitude: number;
      longitude: number;
    },
    createdById: number,
    reportedOn: number,
    modifiedOn: number) {
    this.id = id;
    this.deviceId = deviceId;
    this.villageId = villageId;
    this.assignmentId = assignmentId;
    this.history = history;
    this.status = status;
    this.location = location;
    this.createdById = createdById;
    this.reportedOn = reportedOn;
    this.modifiedOn = modifiedOn;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HouseAdapter implements Adapter<House> {
  adapt(item: any): House {
    const history: Action[] = item.history.map((action: any) => {
      return new Action(
        action.id,
        action.deviceId,
        action.assignmentId,
        action.createdByUser,
        action.status,
        action.reportedOn
      );
    });
    const historyDesc = history.sort((a, b) => {
      return b.reportedOn - a.reportedOn;
    });

    let status: Status;

    switch (item.status) {
      case Status.VISITED: {
        status = Status.VISITED;
        break;
      }
      case Status.NOT_AT_HOME: {
        status = Status.NOT_AT_HOME;
        break;
      }
      case Status.REFUSED_1: {
        let count = 0;

        for (let action of historyDesc) {
          if (action.status === Status.REFUSED_1) {
            count++;
          } else {
            break;
          }
        }

        switch (count) {
          case 1: {
            status = Status.REFUSED_1;
            break;
          }
          case 2: {
            status = Status.REFUSED_2;
            break;
          }
          case 3: {
            status = Status.REFUSED_3;
            break;
          }
          case 4: {
            status = Status.REFUSED_4;
            break;
          }
          case 5: {
            status = Status.REFUSED_5;
            break;
          }
          default: {
            status = Status.FORBIDDEN;
            break;
          }
        }

        break;
      }
      default: {
        status = Status.VISITED;
        break;
      }
    }

    return new House(
      item.id,
      item.deviceId,
      item.villageId,
      item.assignmentId,
      history,
      status,
      item.location,
      item.createdById,
      item.reportedOn,
      item.modifiedOn
    );
  }
}
