import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";

export class CampaignStats {
  readonly villagesCount: number;
  readonly area: number;
  readonly surveyed: {
    readonly area: number;
    readonly percentage: number;
  };
  readonly waterSourcesCount: number;
  readonly deletedWaterSourcesCount: number;
  readonly treatedWaterSources: {
    readonly now: number,
    readonly total: number
  };
  readonly issuedWaterSources: {
    readonly now: number,
    readonly total: number
  };
  readonly sampledWaterSources: {
    readonly now: {
      readonly count: number,
      readonly percentage: number
    };
    readonly total: {
      readonly count: number,
      readonly percentage: number
    };
    readonly totalPositive: {
      readonly count: number,
      readonly percentage: number
    };
    readonly nowPositive: {
      readonly count: number,
      readonly percentage: number
    };
  };
  treatAndIssueProgressChart: {
    date: number,
    new: number,
    treated: number,
    issued: number,
    treated_issued: number
  }[];

  constructor(
    villagesCount: number,
    area: number,
    surveyed: {
      readonly area: number,
      readonly percentage: number
    },
    waterSourcesCount: number,
    deletedWaterSourcesCount: number,
    treatedWaterSources: {
      readonly now: number,
      readonly total: number
    },
    issuedWaterSources: {
      readonly now: number,
      readonly total: number
    },
    sampledWaterSources: {
      readonly now: {
        readonly count: number,
        readonly percentage: number
      };
      readonly total: {
        readonly count: number,
        readonly percentage: number
      };
      readonly totalPositive: {
        readonly count: number,
        readonly percentage: number
      };
      readonly nowPositive: {
        readonly count: number,
        readonly percentage: number
      };
    },
    treatAndIssueProgressChart: {
      date: number,
      new: number,
      treated: number,
      issued: number,
      treated_issued: number
    }[]
  ) {
    this.villagesCount = villagesCount;
    this.area = area;
    this.surveyed = surveyed;
    this.waterSourcesCount = waterSourcesCount;
    this.deletedWaterSourcesCount = deletedWaterSourcesCount;
    this.treatedWaterSources = treatedWaterSources;
    this.issuedWaterSources = issuedWaterSources;
    this.sampledWaterSources = sampledWaterSources;
    this.treatAndIssueProgressChart = treatAndIssueProgressChart;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CampaignStatsAdapter implements Adapter<CampaignStats> {
  adapt(item: any): CampaignStats {
    return new CampaignStats(
      item.villagesCount,
      item.area,
      {
        area: item.surveyed.area,
        percentage: item.surveyed.percentage
      },
      item.waterSourcesCount,
      item.deletedWaterSourcesCount,
      {
        now: item.treatedWaterSources.now,
        total: item.treatedWaterSources.total
      },
      {
        now: item.issuedWaterSources.now,
        total: item.issuedWaterSources.total
      },
      {
        now: {
          count: item.sampledWaterSources.now.count,
          percentage: item.sampledWaterSources.now.percentage,
        },
        total: {
          count: item.sampledWaterSources.total.count,
          percentage: item.sampledWaterSources.total.percentage,
        },
        totalPositive: {
          count: item.sampledWaterSources.totalPositive.count,
          percentage: item.sampledWaterSources.totalPositive.percentage
        },
        nowPositive: {
          count: item.sampledWaterSources.nowPositive.count,
          percentage: item.sampledWaterSources.nowPositive.percentage
        },
      },
      item.treatAndIssueProgressChart.map((chartItem: any) => {
        return {
          date: chartItem.date,
          new: chartItem.new,
          treated: chartItem.treated,
          issued: chartItem.issued,
          treated_issued: chartItem.treated_issued
        }
      })
    );
  }
}
