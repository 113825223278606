import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidenavState = new BehaviorSubject<string | null>(null);
  public sidenavState$ = this.sidenavState.asObservable();

  get isOpen() {
    return this.sidenavState.value !== null;
  }

  get openedContent() {
    return this.sidenavState.value;
  }

  public toggle(layout: string | null) {
    this.sidenavState.next(layout);
  }

  public hide() {
    this.sidenavState.next(null);
  }
}
