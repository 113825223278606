import {Injectable} from "@angular/core";
import {Adapter} from "./Adapter";

export class Search {
  name: string;
  mapbox_id: string;
  place_formatted: string;

  constructor(data: any) {
    this.name = data.name;
    this.mapbox_id = data.mapbox_id;
    this.place_formatted = data.place_formatted;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SearchAdapter implements Adapter<Search> {
  adapt(item: any): Search {
    return new Search(item);
  }
}
