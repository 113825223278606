<div class="d-flex align-content-start flex-fill flex-wrap">
  <form [formGroup]="filterForm" class="flex-item d-flex flex-wrap">
    <ng-select [items]="workers"
               bindLabel="name"
               bindValue="id"
               class="flex-item m-1 align-self-start flex-grow-1 auto-grow"
               formControlName="workerId"
               id="worker"
               placeholder="{{ 'task.filter.workerPlaceholder' | translate }}">
    </ng-select>
    <ng-select [items]="villages"
               bindLabel="name"
               bindValue="id"
               class="flex-item m-1 align-self-start flex-grow-1"
               formControlName="villageId"
               id="village"
               placeholder="{{ 'task.filter.villagePlaceholder' | translate }}">
    </ng-select>
    <ng-select [items]="workTypes"
               bindLabel="name"
               bindValue="id"
               class="flex-item m-1 align-self-start flex-grow-1"
               formControlName="workType"
               id="workTypes"
               placeholder="{{ 'task.filter.workTypePlaceholder' | translate }}">
    </ng-select>
    <ng-select [items]="statuses"
               bindLabel="name"
               bindValue="id"
               class="flex-item m-1 align-self-start flex-grow-1"
               formControlName="status"
               id="statuses"
               placeholder="{{ 'task.filter.statusPlaceholder' | translate }}">
    </ng-select>
    <mat-form-field class="mx-1 mt-1" style="width: 45%">
      <mat-label>{{ 'task.filter.startDateHint' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="fromPicker">
        <input formControlName="startedOnFrom" matStartDate placeholder="{{ 'task.filter.fromDate' | translate }}">
        <input formControlName="startedOnTo" matEndDate placeholder="{{ 'task.filter.toDate' | translate }}">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="fromPicker" matIconSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #fromPicker>
        <mat-datepicker-actions>
          <button class="btn btn-secondary me-1" matDatepickerCancel>{{ 'task.filter.cancel' | translate }}</button>
          <button class="btn btn-primary" matDatepickerApply>{{ 'task.filter.apply' | translate }}</button>
        </mat-datepicker-actions>
      </mat-date-range-picker>
    </mat-form-field>
    <mat-form-field class="ms-1 mt-1" style="width: 45%">
      <mat-label>{{ 'task.filter.endDateHint' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="toPicker">
        <input formControlName="completedOnFrom" matStartDate placeholder="{{ 'task.filter.fromDate' | translate }}">
        <input formControlName="completedOnTo" matEndDate placeholder="{{ 'task.filter.toDate' | translate }}">
      </mat-date-range-input>
      <mat-datepicker-toggle [for]="toPicker" matIconSuffix></mat-datepicker-toggle>
      <mat-date-range-picker #toPicker>
        <mat-datepicker-actions>
          <button class="btn btn-secondary me-1" matDatepickerCancel>{{ 'task.filter.cancel' | translate }}</button>
          <button class="btn btn-primary" matDatepickerApply>{{ 'task.filter.apply' | translate }}</button>
        </mat-datepicker-actions>
      </mat-date-range-picker>
    </mat-form-field>
  </form>
  <div class="d-flex flex-column mt-1">
    <button (click)="onFilterApply()" class="btn btn-outline-success btn-sm m-1 flex-item flex-fill" type="button">
      {{ 'task.apply' | translate }}
    </button>
    <button (click)="onFilterClear()" class="btn btn-outline-secondary btn-sm m-1 flex-item flex-fill" type="button">
      {{ 'task.clear' | translate }}
    </button>
  </div>
</div>

