import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatPipe implements PipeTransform {
  transform(milliseconds: number): string {
    const seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    minutes = minutes % 60;
    hours = hours % 24;

    let daysPart = days > 0 ? `${days}d` : '';
    let hoursPart = `${hours}h`;
    let minutesPart = `${minutes}m`;

    return [daysPart, hoursPart, minutesPart].filter(Boolean).join(' ');
  }
}
