import {Component, HostListener} from '@angular/core';
import {SidebarService} from "./sidebar.service";

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public screenwidth: number = window.innerWidth;

  constructor(protected sidebarService: SidebarService) {
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: { target: { innerWidth: number }; }) {
    this.screenwidth = event.target.innerWidth;
  }

  onClick(layout: string) {
    if (this.sidebarService.isOpen && this.sidebarService.openedContent === layout) {
      this.sidebarService.toggle(null);
    } else {
      this.sidebarService.toggle(layout);
    }
  }
}
