import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {filter, map, Observable} from "rxjs";
import {CampaignStats} from "../../core/CampaignStatsAdapter";

@Component({
  selector: 'app-report-campaign',
  templateUrl: './campaign.component.html',
  styleUrl: './campaign.component.scss'
})
export class CampaignComponent implements OnChanges {
  @Input()
  campaignStats$: Observable<CampaignStats | undefined>;

  stats$: Observable<{
    surveyed: { area: number, percentage: number };
    sampledWaterSources: {
      nowPositiveCount: number;
      nowPositivePercentage: number;
      nowCount: number,
      nowPercentage: number,
      totalCount: number,
      totalPercentage: number,
      totalPositiveCount: number;
      totalPositivePercentage: number;
    };
    waterSourcesCount: number;
    deletedWaterSourcesCount: number;
    issuedWaterSourcesCount: number;
    totalIssuedWaterSourcesCount: number;
    treatedWaterSourcesCount: number;
    totalTreatedWaterSourcesCount: number;
    totalArea: number;
    villagesCount: number
  }>;

  ngOnChanges(changes: SimpleChanges): void {
    this.stats$ = this.campaignStats$.pipe(
      filter((campaignStats: CampaignStats | undefined) => {
        return campaignStats !== undefined;
      }),
      map((campaignStats: CampaignStats | undefined) => {
        return {
          surveyed: campaignStats!.surveyed,
          sampledWaterSources: {
            nowPositiveCount: campaignStats!.sampledWaterSources.nowPositive.count,
            nowPositivePercentage: campaignStats!.sampledWaterSources.nowPositive.percentage,
            nowCount: campaignStats!.sampledWaterSources.now.count,
            nowPercentage: campaignStats!.sampledWaterSources.now.percentage,
            totalCount: campaignStats!.sampledWaterSources.total.count,
            totalPercentage: campaignStats!.sampledWaterSources.total.percentage,
            totalPositiveCount: campaignStats!.sampledWaterSources.totalPositive.count,
            totalPositivePercentage: campaignStats!.sampledWaterSources.totalPositive.percentage
          },
          waterSourcesCount: campaignStats!.waterSourcesCount,
          deletedWaterSourcesCount: campaignStats!.deletedWaterSourcesCount,
          issuedWaterSourcesCount: campaignStats!.issuedWaterSources.now,
          totalIssuedWaterSourcesCount: campaignStats!.issuedWaterSources.total,
          treatedWaterSourcesCount: campaignStats!.treatedWaterSources.now,
          totalTreatedWaterSourcesCount: campaignStats!.treatedWaterSources.total,
          totalArea: campaignStats!.area,
          villagesCount: campaignStats!.villagesCount
        }
      })
    );
  }
}
