@if (options$ | async; as options) {
  <div class="card card-body border text-bg-light p-0">
    <apx-chart [chart]="options.chart"
               [series]="options.series"
               [colors]="options.colors"
               [dataLabels]="options.dataLabels"
               [xaxis]="options.xaxis"
               [fill]="options.fill"
               [legend]="options.legend"
               [tooltip]="options.tooltip">
    </apx-chart>
  </div>
}
