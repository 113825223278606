import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";

export class AnimalShelter {
  id: number;
  deviceId: string;
  assignmentId: number;
  location: {
    latitude: number;
    longitude: number;
  };
  villageId: number;
  reportedOn: number;
  creator: {
    id: number;
    fullName: string;
  };
  modifiedOn: number;

  constructor(
    id: number,
    deviceId: string,
    assignmentId: number,
    location: {
      latitude: number;
      longitude: number;
    },
    villageId: number,
    reportedOn: number,
    creator: {
      id: number;
      fullName: string;
    },
    modifiedOn: number) {
    this.id = id;
    this.deviceId = deviceId;
    this.assignmentId = assignmentId;
    this.location = location;
    this.villageId = villageId;
    this.reportedOn = reportedOn;
    this.creator = creator;
    this.modifiedOn = modifiedOn;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnimalShelterAdapter implements Adapter<AnimalShelter> {
  adapt(item: any): AnimalShelter {
    return new AnimalShelter(
      item.id,
      item.deviceId,
      item.assignmentId,
      item.location,
      item.villageId,
      item.reportedOn,
      item.creator,
      item.modifiedOn
    );
  }
}
