import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {filter, map, Observable} from "rxjs";
import * as _ from 'lodash';
import {TranslateService} from "@ngx-translate/core";
import {CampaignService} from "../../campaign/campaign.service";
import {CampaignStats} from "../../core/CampaignStatsAdapter";

@Component({
  selector: 'app-treat-chart-3',
  templateUrl: './treat-chart-3.component.html',
  styleUrl: './treat-chart-3.component.scss'
})
export class TreatChart3Component implements OnChanges {
  private static _chartId = 'treat-chart-3';
  @Input()
  campaignStats$: Observable<CampaignStats | undefined>;
  options$: Observable<any>;

  constructor(private campaignService: CampaignService,
              private translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options$ = this.campaignStats$.pipe(
      filter((campaignStats: CampaignStats | undefined) => {
        return campaignStats !== undefined;
      }),
      map((campaignStats: CampaignStats | undefined) => {
        return _.sortBy(campaignStats!.treatAndIssueProgressChart, 'date');
      }),
      map((treatAndIssueProgressChart: {
        date: number,
        new: number,
        treated: number,
        issued: number,
        treated_issued: number
      }[]) => {
        const series = [
          {
            name: this.translateService.instant('status.new'),
            data: treatAndIssueProgressChart
              .map((item) => {
                return {
                  x: item.date,
                  y: item.new
                }
              })
          },
          {
            name: this.translateService.instant('status.treated'),
            data: treatAndIssueProgressChart
              .map((item) => {
                return {
                  x: item.date,
                  y: item.treated
                }
              })
          },
          {
            name: this.translateService.instant('status.treated-issued'),
            data: treatAndIssueProgressChart
              .map((item) => {
                return {
                  x: item.date,
                  y: item.treated_issued
                }
              })
          },
          {
            name: this.translateService.instant('status.issued'),
            data: treatAndIssueProgressChart
              .map((item) => {
                return {
                  x: item.date,
                  y: item.issued
                }
              })
          }
        ];

        const chart = {
          id: TreatChart3Component._chartId,
          background: 'rgb(248, 249, 250, 0)',
          type: 'area',
          height: 380,
          stacked: true,
          events: {
            zoomed: (chartContext: any, {xaxis}: any) => {
              const minX = chartContext.data.twoDSeriesX[0];
              const maxX = chartContext.data.twoDSeriesX[chartContext.data.twoDSeriesX.length - 1];
              this.moveToEnd(minX, maxX);
            }
          },
          toolbar: {
            tools: {
              pan: false
            }
          }
        };
        const colors = ['#99ECFF', '#69CC40', '#B4D33E', '#FFD93B'];

        return {
          series: series,
          chart: chart,
          colors: colors,
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          fill: {
            type: 'solid',
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left'
          },
          xaxis: {
            type: 'datetime',
            labels: {
              maxHeight: 200,
              rotate: -45,
              datetimeFormatter: {
                year: 'yyyy',
                month: 'MMM \'yy',
                day: 'dd/MM/yy',
                hour: 'HH:mm'
              }
            }
          },
        }
      }));
  }

  private moveToEnd(start: number, end: number) {
    this.campaignService.getCampaignStats({start: start, end: end});
  }
}
