import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";

export class Campaign {
  id: number;
  name: string;
  country: string;
  settings?: Settings;
  timezone?: string;

  constructor(
    id: number,
    name: string,
    country: string,
    settings?: Settings,
    timezone?: string
  ) {
    this.id = id;
    this.name = name;
    this.country = country;
    this.settings = settings;
    this.timezone = timezone;
  }
}

export class Settings {
  sampleExpiration: number;
  sprayExpiration: number;
  surveyExpiration: number;
  surveyBeltRadius: number;
  surveyCoreRadius: number;
  small: number;
  medium: number;
  large: number;
  huge: number;
  zoomLimit: number;
  workModes: string[];
  supportedWaterSourceTypes: string[];
  newWaterSourceWizard: NewWaterSourceStep[];
  shouldShowLarvaePresentDialogInTreat: boolean;
  shouldShowAnophelesL12PresentDialogInTreat: boolean;
  shouldShowAnophelesL34PresentDialogInTreat: boolean;
  shouldShowCulexL12PresentDialogInTreat: boolean;
  shouldShowCulexL34PresentDialogInTreat: boolean;
  shouldShowPupaePresentDialogInTreat: boolean;
  flexibleTaskOrder: boolean;

  constructor(
    sampleExpiration: number,
    sprayExpiration: number,
    surveyExpiration: number,
    surveyBeltRadius: number,
    surveyCoreRadius: number,
    small: number,
    medium: number,
    large: number,
    huge: number,
    zoomLimit: number,
    workModes: string[],
    supportedWaterSourceTypes: string[],
    newWaterSourceWizard: NewWaterSourceStep[],
    shouldShowLarvaePresentDialogInTreat: boolean,
    shouldShowAnophelesL12PresentDialogInTreat: boolean,
    shouldShowAnophelesL34PresentDialogInTreat: boolean,
    shouldShowCulexL12PresentDialogInTreat: boolean,
    shouldShowCulexL34PresentDialogInTreat: boolean,
    shouldShowPupaePresentDialogInTreat: boolean,
    flexibleTaskOrder: boolean
  ) {
    this.sampleExpiration = sampleExpiration;
    this.sprayExpiration = sprayExpiration;
    this.surveyExpiration = surveyExpiration;
    this.surveyBeltRadius = surveyBeltRadius;
    this.surveyCoreRadius = surveyCoreRadius;
    this.small = small;
    this.medium = medium;
    this.large = large;
    this.huge = huge;
    this.zoomLimit = zoomLimit;
    this.workModes = workModes;
    this.supportedWaterSourceTypes = supportedWaterSourceTypes;
    this.newWaterSourceWizard = newWaterSourceWizard;
    this.shouldShowLarvaePresentDialogInTreat = shouldShowLarvaePresentDialogInTreat;
    this.shouldShowAnophelesL12PresentDialogInTreat = shouldShowAnophelesL12PresentDialogInTreat;
    this.shouldShowAnophelesL34PresentDialogInTreat = shouldShowAnophelesL34PresentDialogInTreat;
    this.shouldShowCulexL12PresentDialogInTreat = shouldShowCulexL12PresentDialogInTreat;
    this.shouldShowCulexL34PresentDialogInTreat = shouldShowCulexL34PresentDialogInTreat;
    this.shouldShowPupaePresentDialogInTreat = shouldShowPupaePresentDialogInTreat;
    this.flexibleTaskOrder = flexibleTaskOrder;
  }
}

export enum ValueType {
  BOOLEAN = "boolean",
  DOUBLE = "double",
  FLOAT = "float",
  IMAGE = "image",
  INT = "int",
  LONG = "long",
  STRING = "string",
}

export enum ValueFieldKey {
  DEPTH = "depth",
  HAS_WATER = "hasWater",
  IMAGE = "images",
  IS_POLLUTED = "isPolluted",
  IS_SHADED = "isShaded",
  IS_TEMPORARY = "temporary",
  IS_VEGETATION = "isVegetation",
  SIZE = "size",
  TYPE = "type",
}

export class NewWaterSourceStep {
  valueFieldKey: ValueFieldKey | string;
  isArray: boolean;
  valueType: ValueType;
  question?: LanguageText[];
  options?: LanguageText[];

  constructor(
    valueFieldKey: ValueFieldKey,
    isArray: boolean,
    valueType: ValueType,
    question?: LanguageText[],
    options?: LanguageText[]) {
    this.valueFieldKey = valueFieldKey;
    this.isArray = isArray;
    this.valueType = valueType;
    this.question = question;
    this.options = options;
  }
}

export class LanguageText {
  locale: string;
  text: string;
  value?: string;

  constructor(
    locale: string,
    text: string,
    value?: string,
  ) {
    this.locale = locale;
    this.text = text;
    this.value = value;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CampaignAdapter implements Adapter<Campaign> {
  adapt(item: any): Campaign {
    let settings: Settings | undefined;

    if (item.settings) {
      let newWaterSourceWizard: NewWaterSourceStep[];

      if (item.settings.newWaterSourceWizard) {
        newWaterSourceWizard = item.settings.newWaterSourceWizard.map((step: any) => new NewWaterSourceStep(
          step.valueFieldKey,
          step.isArray,
          step.valueType,
          step.question ? step.question.map((question: any) => new LanguageText(
            question.locale,
            question.text,
            question.value
          )) : undefined,
          step.options ? step.options.map((option: any) => new LanguageText(
            option.locale,
            option.text,
            option.value
          )) : undefined
        ));
      } else {
        newWaterSourceWizard = [];
      }

      settings = new Settings(
        item.settings.sampleExpiration,
        item.settings.sprayExpiration,
        item.settings.surveyExpiration,
        item.settings.surveyBeltRadius,
        item.settings.surveyCoreRadius,
        item.settings.small,
        item.settings.medium,
        item.settings.large,
        item.settings.huge,
        item.settings.zoomLimit,
        item.settings.workModes,
        item.settings.supportedWaterSourceTypes,
        newWaterSourceWizard,
        item.settings.shouldShowLarvaePresentDialogInTreat,
        item.settings.shouldShowAnophelesL12PresentDialogInTreat,
        item.settings.shouldShowAnophelesL34PresentDialogInTreat,
        item.settings.shouldShowCulexL12PresentDialogInTreat,
        item.settings.shouldShowCulexL34PresentDialogInTreat,
        item.settings.shouldShowPupaePresentDialogInTreat,
        item.settings.flexibleTaskOrder
      )
    }

    return new Campaign(
      item.id,
      item.name,
      item.country,
      settings,
      item.timezone
    );
  }
}
