import {LanguageText, NewWaterSourceWizardStep, ValueType} from "./campaign-config.dto";

export const defaultNewWaterSourceWizard: NewWaterSourceWizardStep[] = [
  new NewWaterSourceWizardStep(
    "images",
    true,
    ValueType.IMAGE
  ),
  new NewWaterSourceWizardStep(
    "type",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Select Habitat Type",
      ),
      new LanguageText(
        "pt",
        "Escolher tipo de ponto de água",
      ),
      new LanguageText(
        "fr",
        "Type",
      )
    ],
    [
      new LanguageText(
        "en",
        "Swamp",
        "swamp"
      ),
      new LanguageText(
        "en",
        "Pond",
        "pond"
      ),
      new LanguageText(
        "en",
        "Puddle",
        "puddle"
      ),
      new LanguageText(
        "en",
        "Manmade",
        "manmade"
      ),
      new LanguageText(
        "en",
        "Agricultural",
        "agriculture"
      ),
      new LanguageText(
        "en",
        "Construcion",
        "construction"
      ),
      new LanguageText(
        "en",
        "Animal tracks",
        "footprint"
      ),
      new LanguageText(
        "en",
        "Tire tracks",
        "tracks"
      ),
      new LanguageText(
        "en",
        "Ditch",
        "ditch"
      ),
      new LanguageText(
        "en",
        "Pool",
        "pool"
      ),
      new LanguageText(
        "en",
        "Tire",
        "tire"
      ),
      new LanguageText(
        "en",
        "Other",
        "other"
      ),
      new LanguageText(
        "pt",
        "Pântano",
        "swamp"
      ),
      new LanguageText(
        "pt",
        "Lago",
        "pond"
      ),
      new LanguageText(
        "pt",
        "Poça",
        "puddle"
      ),
      new LanguageText(
        "pt",
        "Artificial",
        "manmade"
      ),
      new LanguageText(
        "pt",
        "Agrícola",
        "agriculture"
      ),
      new LanguageText(
        "pt",
        "Construção",
        "construction"
      ),
      new LanguageText(
        "pt",
        "Pegadas",
        "footprint"
      ),
      new LanguageText(
        "pt",
        "Marcas de pneus",
        "tracks"
      ),
      new LanguageText(
        "pt",
        "Vala",
        "ditch"
      ),
      new LanguageText(
        "pt",
        "Piscina",
        "pool"
      ),
      new LanguageText(
        "pt",
        "Pneu",
        "tire"
      ),
      new LanguageText(
        "pt",
        "Outros",
        "other"
      ),
      new LanguageText(
        "fr",
        "Marais",
        "swamp"
      ),
      new LanguageText(
        "fr",
        "Étang",
        "pond"
      ),
      new LanguageText(
        "fr",
        "Flaque d'eau",
        "puddle"
      ),
      new LanguageText(
        "fr",
        "Artificiel",
        "manmade"
      ),
      new LanguageText(
        "fr",
        "Agriculture",
        "agriculture"
      ),
      new LanguageText(
        "fr",
        "Construcion",
        "construction"
      ),
      new LanguageText(
        "fr",
        "Traces d'animaux",
        "footprint"
      ),
      new LanguageText(
        "fr",
        "Traces de pneus",
        "tracks"
      ),
      new LanguageText(
        "fr",
        "Fossé",
        "ditch"
      ),
      new LanguageText(
        "fr",
        "Piscine",
        "pool"
      ),
      new LanguageText(
        "fr",
        "Pneu",
        "tire"
      ),
      new LanguageText(
        "fr",
        "Autre",
        "other"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "isCustomTemporary",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Is the habitat temporary?"
      ),
      new LanguageText(
        "pt",
        "O ponto d’água é temporário?"
      ),
      new LanguageText(
        "fr",
        "L'habitat est-il temporaire?"
      )
    ],
    [
      new LanguageText(
        "en",
        "Temporary",
        "temporary"
      ),
      new LanguageText(
        "en",
        "Semi-permanent",
        "semi-permanent"
      ),
      new LanguageText(
        "en",
        "Permanent",
        "permanent"
      ),
      new LanguageText(
        "pt",
        "Temporário",
        "temporary"
      ),
      new LanguageText(
        "pt",
        "Semi-permanente",
        "semi-permanent"
      ),
      new LanguageText(
        "pt",
        "Permanente",
        "permanent"
      ),
      new LanguageText(
        "fr",
        "Temporaire",
        "temporary"
      ),
      new LanguageText(
        "fr",
        "Semi-permanent",
        "semi-permanent"
      ),
      new LanguageText(
        "fr",
        "Permanent",
        "permanent"
      ),
    ]
  ),
  new NewWaterSourceWizardStep(
    "temporary",
    false,
    ValueType.BOOLEAN,
    [
      new LanguageText(
        "en",
        "Is the habitat temporary?"
      ),
      new LanguageText(
        "pt",
        "O ponto d’água é temporário?"
      ),
      new LanguageText(
        "fr",
        "L'habitat est-il temporaire?"
      )
    ],
    [
      new LanguageText(
        "en",
        "Yes",
        "true"
      ),
      new LanguageText(
        "en",
        "No",
        "false"
      ),
      new LanguageText(
        "pt",
        "Sim",
        "true"
      ),
      new LanguageText(
        "pt",
        "Não",
        "false"
      ),
      new LanguageText(
        "fr",
        "Oui",
        "true"
      ),
      new LanguageText(
        "fr",
        "Non",
        "false"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "isCustomPolluted",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Is the water polluted?"
      ),
      new LanguageText(
        "pt",
        "O ponto d’água é poluído?"
      ),
      new LanguageText(
        "fr",
        "L'eau est-elle polluée?"
      )
    ],
    [
      new LanguageText(
        "en",
        "Clear water",
        "clear water"
      ),
      new LanguageText(
        "en",
        "Turbid water",
        "turbid water"
      ),
      new LanguageText(
        "pt",
        "Água limpa",
        "clear water"
      ),
      new LanguageText(
        "pt",
        "Água turva",
        "turbid water"
      ),
      new LanguageText(
        "fr",
        "Eau claire",
        "clear water"
      ),
      new LanguageText(
        "fr",
        "Eau turbide",
        "turbid water"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "isPolluted",
    false,
    ValueType.BOOLEAN,
    [
      new LanguageText(
        "en",
        "Is the water polluted?"
      ),
      new LanguageText(
        "pt",
        "O ponto d’água é poluído?"
      ),
      new LanguageText(
        "fr",
        "L'eau est-elle polluée?"
      )
    ],
    [
      new LanguageText(
        "en",
        "Yes",
        "true"
      ),
      new LanguageText(
        "en",
        "No",
        "false"
      ),
      new LanguageText(
        "pt",
        "Sim",
        "true"
      ),
      new LanguageText(
        "pt",
        "Não",
        "false"
      ),
      new LanguageText(
        "fr",
        "Oui",
        "true"
      ),
      new LanguageText(
        "fr",
        "Non",
        "false"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "isVegetation",
    false,
    ValueType.BOOLEAN,
    [
      new LanguageText(
        "en",
        "Vegetation cover?"
      ),
      new LanguageText(
        "pt",
        "Vegetação na água?"
      ),
      new LanguageText(
        "fr",
        "L'eau contient-elle de la végétation?"
      )
    ],
    [
      new LanguageText(
        "en",
        "Yes",
        "true"
      ),
      new LanguageText(
        "en",
        "No",
        "false"
      ),
      new LanguageText(
        "pt",
        "Sim",
        "true"
      ),
      new LanguageText(
        "pt",
        "Não",
        "false"
      ),
      new LanguageText(
        "fr",
        "Oui",
        "true"
      ),
      new LanguageText(
        "fr",
        "Non",
        "false"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "size",
    false,
    ValueType.STRING,
    [
      new LanguageText(
        "en",
        "Water body size"
      ),
      new LanguageText(
        "pt",
        "Largura do ponto d'água"
      ),
      new LanguageText(
        "fr",
        "Taille"
      )
    ],
    [
      new LanguageText(
        "en",
        "Very small\n(under 1 m)",
        "tiny"
      ),
      new LanguageText(
        "en",
        "Small\n(1-3 m)",
        "small"
      ),
      new LanguageText(
        "en",
        "Medium\n(3-10 m)",
        "medium"
      ),
      new LanguageText(
        "en",
        "Large\n(over 10 m)",
        "large"
      ),
      new LanguageText(
        "pt",
        "Muito pequeno\n(menos de 1m)",
        "tiny"
      ),
      new LanguageText(
        "pt",
        "Pequeno\n(1–3m)",
        "small"
      ),
      new LanguageText(
        "pt",
        "Médio\n(3–10m)",
        "medium"
      ),
      new LanguageText(
        "pt",
        "Grande\n(acima de 10m)",
        "large"
      ),
      new LanguageText(
        "fr",
        "Très petite\n(moins de 1m)",
        "tiny"
      ),
      new LanguageText(
        "fr",
        "Petite\n(1-3m)",
        "small"
      ),
      new LanguageText(
        "fr",
        "Moyenne\n(3–10m)",
        "medium"
      ),
      new LanguageText(
        "fr",
        "Grande\n(plus de 10m)",
        "large"
      )
    ]
  ),
  new NewWaterSourceWizardStep(
    "depth",
    false,
    ValueType.INT,
    [
      new LanguageText(
        "en",
        "Water body depth"
      ),
      new LanguageText(
        "pt",
        "Profundidade da água"
      ),
      new LanguageText(
        "fr",
        "Profondeur"
      )
    ],
    [
      new LanguageText(
        "en",
        "Less than 10cm",
        5
      ),
      new LanguageText(
        "en",
        "Between 10cm and 50cm",
        25
      ),
      new LanguageText(
        "en",
        "Greater than 50cm",
        70
      ),
      new LanguageText(
        "pt",
        "Menos de 10 cm",
        5
      ),
      new LanguageText(
        "pt",
        "Entre 10 e 50 cm",
        25
      ),
      new LanguageText(
        "pt",
        "Maior que 50 cm",
        70
      ),
      new LanguageText(
        "fr",
        "Moins de 10cm",
        5
      ),
      new LanguageText(
        "fr",
        "Entre 10 et 50cm",
        25
      ),
      new LanguageText(
        "fr",
        "Plus de 50cm",
        70
      )
    ]
  )
]
