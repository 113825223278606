import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";
import * as mgrs from "mgrs";

export enum ChunkType {
  CORE = 'core',
  BELT = 'belt'
}

export class Chunk {
  id: string;
  type: ChunkType;
  bbox: number[];
  cords: number[][][];

  constructor(
    id: string,
    type: ChunkType,
    bbox: number[],
    cords: number[][][]
  ) {
    this.id = id;
    this.type = type;
    this.bbox = bbox;
    this.cords = cords;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChunkAdapter implements Adapter<Chunk> {
  adapt(item: any): Chunk {
    return new Chunk(
      item.id,
      item.type,
      mgrs.inverse(item.id),
      item.cords
    );
  }
}
