@if (stats$ | async; as stats) {
  <div class="card card-body border container text-bg-light p-2">
    <div class="row g-0">
      <div class="col">
        <p [ngbTooltip]="'campaign_report.villages_count' | translate " container="body">
          <i class="fa-solid fa-city fa-lg fa-fw"></i>
          <strong>{{ stats.villagesCount }}</strong>
        </p>
      </div>
      <div class="col">
        <p [ngbTooltip]="'campaign_report.totalArea' | translate " container="body">
          <i class="fa-solid fa-clone fa-xl fa-fw"></i>
          <strong>{{ stats.totalArea | number: '1.0-2' }}{{ 'campaign_report.area' | translate }}</strong>
        </p>
      </div>
    </div>
    <div class="row g-0">
      <div class="col">
        <p [ngbTooltip]="'campaign_report.surveyedArea' | translate " container="body">
          <i class="fa-solid fa-table-cells fa-xl fa-fw"></i>
          <strong>{{ stats.surveyed.area | number: '1.0-2' }}{{ 'campaign_report.area' | translate }}
            ({{ stats.surveyed.percentage | number: '1.1-2' }}%)</strong>
        </p>
      </div>
      <div class="col">
        <p [ngbTooltip]="'campaign_report.totalWaterSources' | translate ">
          <img [src]="'assets/svg/water-source-new-info.svg'" alt="">
          <strong>{{ stats.waterSourcesCount }} ({{ 'campaign_report.deleted' | translate }}
            : {{ stats.deletedWaterSourcesCount }})</strong>
        </p>
      </div>
    </div>
    <div class="row g-0">
      <div class="col d-flex align-items-center">
        <img [src]="'assets/svg/water-source-treated-info.svg'" alt="">
        <p [ngbTooltip]="'campaign_report.treatedWaterSources' | translate ">
          <strong>
            {{
              'campaign_report.info_now' | translate: {
                nowCount: stats.treatedWaterSourcesCount,
              }
            }}
          </strong>
          <br>
          <strong>
            {{
              'campaign_report.info_total' | translate: {
                totalCount: stats.totalTreatedWaterSourcesCount,
              }
            }}
          </strong>
        </p>
      </div>
      <div class="col d-flex align-items-center">
        <img [src]="'assets/svg/water-source-issued-info.svg'" alt="">
        <p [ngbTooltip]="'campaign_report.issuedWaterSources' | translate ">
          <strong>
            {{
              'campaign_report.info_now' | translate: {
                nowCount: stats.issuedWaterSourcesCount,
              }
            }}
          </strong>
          <br>
          <strong>
            {{
              'campaign_report.info_total' | translate: {
                totalCount: stats.totalIssuedWaterSourcesCount,
              }
            }}
          </strong>
        </p>
      </div>
    </div>
    <div class="row g-0">
      <div class="col d-flex align-items-center">
        <img [src]="'assets/svg/water-source-sampled-info.svg'" alt="">
        <p [ngbTooltip]="'campaign_report.sampledWaterSources' | translate ">
          <strong>
            {{
              'campaign_report.info_now_with_percentage' | translate: {
                nowCount: stats.sampledWaterSources.nowCount,
                nowPercentage: stats.sampledWaterSources.nowPercentage,
              }
            }}
          </strong>
          <br>
          <strong>
            {{
              'campaign_report.info_total_with_percentage' | translate: {
                totalCount: stats.sampledWaterSources.totalCount,
                totalPercentage: stats.sampledWaterSources.totalPercentage
              }
            }}
          </strong>
        </p>
      </div>
      <div class="col d-flex align-items-center">
        <img [src]="'assets/svg/water-source-sampled-positive-info.svg'" alt="">
        <p [ngbTooltip]="'campaign_report.sampledPositiveWaterSources' | translate ">
          <strong>
            {{
              'campaign_report.info_now_with_percentage' | translate: {
                nowCount: stats.sampledWaterSources.nowPositiveCount,
                nowPercentage: stats.sampledWaterSources.nowPositivePercentage,
              }
            }}
          </strong>
          <br>
          <strong>
            {{
              'campaign_report.info_total_with_percentage' | translate: {
                totalCount: stats.sampledWaterSources.totalPositiveCount,
                totalPercentage: stats.sampledWaterSources.totalPositivePercentage
              }
            }}
          </strong>
        </p>
      </div>
    </div>
  </div>
}
