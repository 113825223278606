import {Adapter} from "./Adapter";
import {Injectable} from "@angular/core";
import * as turf from "@turf/turf";

export class Village {
  readonly id: number;
  name: string;
  readonly center: number[];
  readonly cords: number[][][];
  readonly area: number;
  coreChunksCount?: number;
  beltChunksCount?: number;
  surveyedPercentage?: number;
  treatedWaterSourcesCount?: number;
  issuedWaterSourcesCount?: number;
  sampledWaterSourcesCount?: number;
  sampledNegativeWaterSourcesCount?: number;
  sampledPositiveWaterSourcesCount?: number;
  waterSourcesCount?: number;
  surveyViewTaskFillPattern?: string;
  treatViewTaskFillPattern?: string;
  sampleViewTaskFillPattern?: string;
  surveyTaskId?: number;
  treatTaskId?: number;
  sampleTaskId?: number;
  surveyAssignmentIds?: number[];
  treatAssignmentIds?: number[];
  sampleAssignmentIds?: number[];
  properties?: {
    "totalCount": number,
    "totalAreaSurveyed": number,
    "treatNotExpiredCount": number,
    "sampleNotExpiredCount": number,
    "notExpiredSurveyedArea": number
  };

  constructor(
    id: number,
    name: string,
    center: number[],
    cords: number[][][],
    area: number,
    coreChunksCount?: number,
    beltChunksCount?: number,
    surveyedPercentage?: number,
    treatedWaterSourcesCount?: number,
    issuedWaterSourcesCount?: number,
    sampledWaterSourcesCount?: number,
    sampledNegativeWaterSourcesCount?: number,
    sampledPositiveWaterSourcesCount?: number,
    waterSourcesCount?: number,
    surveyViewTaskFillPattern?: string,
    treatViewTaskFillPattern?: string,
    sampleViewTaskFillPattern?: string,
    surveyTaskId?: number,
    treatTaskId?: number,
    sampleTaskId?: number,
    surveyAssignmentIds?: number[],
    treatAssignmentIds?: number[],
    sampleAssignmentIds?: number[],
    properties?: {
      "totalCount": number,
      "totalAreaSurveyed": number,
      "treatNotExpiredCount": number,
      "sampleNotExpiredCount": number,
      "notExpiredSurveyedArea": number
    }
  ) {
    this.id = id;
    this.name = name;
    this.center = center;
    this.cords = cords;
    this.area = area;
    this.coreChunksCount = coreChunksCount;
    this.beltChunksCount = beltChunksCount;
    this.surveyedPercentage = surveyedPercentage;
    this.treatedWaterSourcesCount = treatedWaterSourcesCount;
    this.issuedWaterSourcesCount = issuedWaterSourcesCount;
    this.sampledWaterSourcesCount = sampledWaterSourcesCount;
    this.sampledNegativeWaterSourcesCount = sampledNegativeWaterSourcesCount;
    this.sampledPositiveWaterSourcesCount = sampledPositiveWaterSourcesCount;
    this.waterSourcesCount = waterSourcesCount;
    this.surveyViewTaskFillPattern = surveyViewTaskFillPattern;
    this.treatViewTaskFillPattern = treatViewTaskFillPattern;
    this.sampleViewTaskFillPattern = sampleViewTaskFillPattern;
    this.surveyTaskId = surveyTaskId;
    this.treatTaskId = treatTaskId;
    this.sampleTaskId = sampleTaskId;
    this.surveyAssignmentIds = surveyAssignmentIds;
    this.treatAssignmentIds = treatAssignmentIds;
    this.sampleAssignmentIds = sampleAssignmentIds;
    this.properties = properties;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VillageAdapter implements Adapter<Village> {
  adapt(item: any): Village {
    const villagePolygon = turf.polygon(item.cords);
    const villageCenter = turf.centerOfMass(villagePolygon);

    return new Village(
      item.id,
      item.name,
      villageCenter.geometry.coordinates,
      item.cords,
      turf.area(villagePolygon) / 1000000,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      item.properties,
    );
  }
}
