import {Component, inject, Input} from '@angular/core';
import {Action} from "../task-list.component";
import {TranslateService} from "@ngx-translate/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {MatButton} from "@angular/material/button";

@Component({
  selector: 'app-select-action',
  standalone: true,
  imports: [
    MatButton
  ],
  templateUrl: './select-action.component.html',
  styleUrl: './select-action.component.scss'
})
export class SelectActionComponent {
  modal = inject(NgbActiveModal);

  @Input()
  action: Action;

  constructor(
    private translate: TranslateService,
  ) {
  }

  getActionTitle(): string {
    return this.translate.instant(`task.actionDialog.${Action[this.action]}.title`);
  }

  getActionMessage(): string {
    return this.translate.instant(`task.actionDialog.${Action[this.action]}.message`);
  }

  getActionPositiveButton(): string {
    return this.translate.instant(`task.actionDialog.${Action[this.action]}.positiveButton`);
  }

  getActionNegativeButton(): string {
    return this.translate.instant(`task.actionDialog.${Action[this.action]}.negativeButton`);
  }
}
