import {Component, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Observable, tap} from "rxjs";
import {CampaignService} from "../campaign/campaign.service";
import {CampaignStats} from "../core/CampaignStatsAdapter";

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
export class ReportComponent implements OnInit {
  private loadingSubject =
    new BehaviorSubject<boolean>(false);

  loading$ = this.loadingSubject.asObservable();

  @Output()
  campaignStats$: Observable<CampaignStats | undefined>;

  constructor(private campaignService: CampaignService) {
  }

  ngOnInit(): void {
    this.campaignService.selectedCampaign$.pipe(
      tap((campaign) => {
          if (campaign) {
            this.campaignStats$ = this.campaignService.campaignStats$;
            this.campaignService.getCampaignStats();
          }
        }
      )).subscribe();

    this.campaignService.campaignStats$.subscribe(value => {
      if (value) {
        this.loadingSubject.next(false);
      } else {
        this.loadingSubject.next(true);
      }
    });
  }
}
